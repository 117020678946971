/** @jsx jsx */
import { jsx } from '@emotion/react'
import { formPages } from '../assets/styles/AboutTwo.styles'

const SuccessContent = () => {
  return (
    <section css={formPages}>
      <div>
        <iframe src="https://forms.monday.com/forms/embed/3d7a598b6658ca9c126be9867d716a41?r=use1" width="650" height="2200"  />
      </div>
    </section>
  )
}

export default SuccessContent
